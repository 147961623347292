import { combineReducers } from 'redux';

import user from './user';
import fastInterview from './fast-interview';

const reducers = {
  user,
  fastInterview,
};

export default combineReducers(reducers);
