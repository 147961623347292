import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Iconify from '@components/iconify';
import { Button, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function SettingsButton() {
  return (
    <Box>
      <Button
        href='https://api.whatsapp.com/send?phone=+551151040260&text=Estamos%20ansiosos%20para%20atend%C3%AA-lo%20da%20melhor%20maneira%20poss%C3%ADvel.%20Como%20podemos%20te%20ajudar?%20%E2%98%BA%EF%B8%8F%0A'
        target='_blank'
        variant='text'
      >
        <Typography color='GrayText'>Suporte</Typography>
        <IconButton
          whileTap='tap'
          whileHover='hover'
          aria-label='settings'
          sx={{
            width: 35,
            height: 35,
          }}
        >
          <Iconify icon='eva:alert-circle-outline' color='GrayText' width={18} />
        </IconButton>
      </Button>
    </Box>
  );
}
