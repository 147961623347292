import { LoadingDot, SplashScreenContainer } from './styles';

export default function SplashScreen({ fullScreen }) {
  return (
    <SplashScreenContainer $fullScreen={fullScreen}>
      <LoadingDot color='#b8fb00' delay='0s' />
      <LoadingDot color='#00f8f7' delay='0.3s' />
      <LoadingDot color='#ffe167' delay='0.6s' />
      <LoadingDot color='#ff9ade' delay='0.9s' />
    </SplashScreenContainer>
  );
}
