import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isAuthenticated } from '@utils/session-api';
import { paths } from '@routes/paths';

export default function AuthGuard({ children }) {
  const navigate = useNavigate();
  const { jobId, companyId } = useParams();
  useEffect(() => {
    if (!isAuthenticated())
      navigate(paths.startInterview.auth.login(jobId, companyId), { replace: true });
  }, [navigate]);

  if (!isAuthenticated()) return null;

  return children;
}
