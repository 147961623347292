import { useMemo } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

// system
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
// options
import RTL from './options/right-to-left';
import { componentsOverrides } from './overrides';
import { customShadows } from './custom-shadows';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const memoizedValue = useMemo(
    () => ({
      palette: {
        ...palette('light'),
      },
      customShadows: {
        ...customShadows('light'),
      },
      shadows: shadows('light'),
      shape: { borderRadius: 20 },
      typography,
    }),
    [],
  );

  const theme = createTheme(memoizedValue);

  theme.components = componentsOverrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <RTL themeDirection='ltl'>
        <CssBaseline />
        {children}
      </RTL>
    </MuiThemeProvider>
  );
}
