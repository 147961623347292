import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

// ----------------------------------------------------------------------

export default function Simple({ children, compact }) {
  return (
    <>
      <Container component='main'>
        <Stack
          sx={{
            py: 4,
            m: 'auto',
            maxWidth: compact ? 600 : 'unset',
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}
