import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { Popover } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '@store/actions/session';
import { useState } from 'react';
import Iconify from '@components/iconify';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from '@routes/paths';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobId, companyId } = useParams();
  const { name, email, photoUrl } = useSelector(state => state.user);
  const { company } = useSelector(state => state.fastInterview);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        whileTap='tap'
        whileHover='hover'
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          width: 40,
          height: 40,
          background: theme => alpha(theme.palette.grey[500], 0.08),
        }}
      >
        <Avatar
          alt
          src={company?.logoURL || photoUrl}
          sx={{
            width: 36,
            height: 36,
            fontSize: 16,
            border: theme => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {name?.charAt(0)?.toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, pb: 1.5, minWidth: 250, textAlign: 'center' }}>
          <Typography variant='subtitle2' noWrap>
            {name || 'Sem nome'}
          </Typography>

          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {email || '-'}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            dispatch(userLogout());
            navigate(paths.startInterview.auth.login(jobId, companyId));
          }}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          <Typography width='100%' display='flex' alignItems='center' justifyContent='center'>
            <Iconify icon='eva:power-outline' /> Sair
          </Typography>
        </MenuItem>
      </Popover>
    </>
  );
}
