import styled from '@emotion/styled';

export const SplashScreenContainer = styled.div`
  height: ${props => (props.$fullScreen ? '100vh' : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingDot = styled.div`
  width: 20px;
  height: 20px;
  margin: 5px;
  border-radius: 50%;
  background-color: ${props => props.color};
  animation: wave 1.2s infinite;
  animation-delay: ${props => props.delay};

  @keyframes wave {
    0%,
    40%,
    100% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-20px);
    }
  }
`;
