export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_RESET = 'USER_RESET';

const initialState = {
  name: '',
  email: '',
  photoUrl: null,
};

export default function userReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case USER_RESET:
      return initialState;

    default:
      return state;
  }
}
