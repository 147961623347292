import Box from '@mui/material/Box';
import LogoBlack from '@assets/img/logo-black.png';
import { Link } from 'react-router-dom';
// ----------------------------------------------------------------------

const Logo = ({ disabledLink = false, sx, ...other }) => {
  const logo = (
    <Box
      {...other}
      component='img'
      src={LogoBlack}
      sx={{
        width: 35,
        height: 35,
        objectFit: 'contain',
        ...sx,
      }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link to='/' style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
      {logo}
    </Link>
  );
};

export default Logo;
