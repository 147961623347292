import { Navigate, useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from "@sentry/react";
import publicRoutes from './paths/public-routes';
import privateRoutes from './paths/private-routes';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

const useSentryRoutes = wrapUseRoutes(useRoutes);

export default function Router() {
  const { id, name, email } = useSelector(state => state.user);
  Sentry.setUser({ id, username: name, email });

  return useSentryRoutes([
    ...publicRoutes,
    ...privateRoutes,
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}
