import SimpleHeader from '@layouts/simple-header';
import SplashScreen from '@components/splash-screen';
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import AuthGuard from '@routes/guards/auth-guard';
// import NavigatorGuard from '@routes/guards/navigator-guard';

// ----------------------------------------------------------------------

const QuestionsPage = lazy(() => import('@pages/questions'));
const QuestionsOrientation = lazy(() => import('@pages/questions-orientation'));
const QuestionsFeedback = lazy(() => import('@pages/questions-feedback'));
const QuestionsTestAudio = lazy(() => import('@pages/questions-test-devices/questions-test-audio'));
const QuestionsTestVideo = lazy(() => import('@pages/questions-test-devices/questions-test-video'));
const Stopped = lazy(() => import('@pages/finished-page'));

// ----------------------------------------------------------------------

const privateRoutes = [
  {
    element: (
      // <NavigatorGuard>
      <AuthGuard>
        <Suspense fallback={<SplashScreen fullScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
      // </NavigatorGuard>
    ),
    children: [
      {
        path: 'questions/job/:jobId/company/:companyId',
        element: (
          <SimpleHeader>
            <Outlet />
          </SimpleHeader>
        ),
        children: [
          { element: <QuestionsPage />, index: true },
          { path: 'test-audio', element: <QuestionsTestAudio /> },
          { path: 'test-video', element: <QuestionsTestVideo /> },
          { path: 'orientation', element: <QuestionsOrientation /> },
          { path: 'feedback', element: <QuestionsFeedback /> },
          { path: 'finished-page', element: <Stopped /> },
        ],
      },
    ],
  },
];

export default privateRoutes;
