import Simple from '@layouts/simple';
import SplashScreen from '@components/splash-screen';
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// import NavigatorGuard from '@routes/guards/navigator-guard';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('@pages/auth/login'));
const TestDevicesPage = lazy(() => import('@pages/test-devices'));
const VagasPage = lazy(() => import('@pages/vagas'));
const StartInterviewPage = lazy(() => import('@pages/auth/start-interview'));
const RegisterPage = lazy(() => import('@pages/auth/register'));
const NotFoundPage = lazy(() => import('@pages/404'));
const NotSuportedNavigatorPage = lazy(() => import('@pages/not-suported-navigator'));
const PrivacyPolicyPage = lazy(() => import('@pages/auth/privacy-policy'));

// ----------------------------------------------------------------------

const publicRoutes = [
  {
    element: (
      // <NavigatorGuard>
      <Simple compact>
        <Suspense fallback={<SplashScreen fullScreen />}>
          <Outlet />
        </Suspense>
      </Simple>
      // </NavigatorGuard>
    ),
    children: [
      {
        path: 'job/:jobId/company/:companyId',
        element: <Outlet />,
        children: [
          { element: <RegisterPage />, index: true },
          { path: 'start-interview', element: <StartInterviewPage /> },
          { path: 'login', element: <LoginPage /> },
          { path: 'register', element: <RegisterPage /> },
        ],
      },
      { path: 'privacy-policies', element: <PrivacyPolicyPage /> },
      { path: 'test-devices', element: <TestDevicesPage /> },
      { path: '404', element: <NotFoundPage /> },
      { path: '403', element: <NotFoundPage /> },
      { path: 'not-supported', element: <NotSuportedNavigatorPage /> },
    ],
  },
  {
    element: (
      <Simple compact>
        <Suspense fallback={<SplashScreen fullScreen />}>
          <NotSuportedNavigatorPage />
        </Suspense>
      </Simple>
    ),
    path: 'not-supported',
  },
  {
    element: (
      <Simple>
        <Suspense fallback={<SplashScreen fullScreen />}>
          <VagasPage />
        </Suspense>
      </Simple>
    ),
    path: 'vagas',
  },
];

export default publicRoutes;
