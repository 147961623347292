export const FAST_INTERVIEW_DATA = 'FAST_INTERVIEW_DATA';
export const FAST_INTERVIEW_SET_CURRENT_QUESTION = 'FAST_INTERVIEW_SET_CURRENT_QUESTION';
export const FAST_INTERVIEW_RESET = 'FAST_INTERVIEW_RESET';

const initialState = {
  company: {},
  job: {},
  id: null,
};

export default function fastInterviewReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FAST_INTERVIEW_DATA:
      return {
        ...state,
        company: payload.company,
        job: payload.job,
        id: payload.id,
      };

    case FAST_INTERVIEW_SET_CURRENT_QUESTION:
      return {
        ...state,
        job: {
          ...state?.job,
          currentQuestion: payload,
        },
      };

    case FAST_INTERVIEW_RESET:
      return initialState;

    default:
      return state;
  }
}
