import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';

export default function ModalNotConnected({ opened }) {
  const [hasRefresh, setHasRefresh] = useState(false);

  useEffect(() => {
    if (opened) setHasRefresh(true);
  }, [opened]);

  return (
    <Dialog open={opened || hasRefresh} maxWidth='md'>
      <Box sx={{ p: 4 }}>
        <Box>
          <DialogTitle sx={{ pb: 2, pt: 0 }}>
            <Typography variant='h4' color='#444'>
              Você está offline!
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ typography: 'body2' }}>
            <Typography variant='body1' color='#444' mb={4}>
              Verifique sua conexão com a internet e atualize a página!
            </Typography>

            <Button
              onClick={() => window.location.reload()}
              fullWidth
              variant='contained'
              color='primary'
            >
              Atualizar página
            </Button>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  );
}
