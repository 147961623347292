import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import HeaderSimple from '@layouts/common/header-simple';

// ----------------------------------------------------------------------

export default function SimpleHeader({ children, compact }) {
  return (
    <>
      <HeaderSimple />

      <Container component='main'>
        <Stack
          sx={{
            pt: 12,
            pb: 3,
            m: 'auto',
            maxWidth: compact ? 600 : 'unset',
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: {
              xs: 'flex-start',
              md: 'center',
            },
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}
