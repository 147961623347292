import { Stack, AppBar, Toolbar, LinearProgress } from '@mui/material';

import Logo from '@components/logo';
import { HEADER } from '../config-layout';
import SettingsButton from './settings-button';
import AccountPopover from './account-popover';
import { useSelector } from 'react-redux';
import { calcPercentQuestions } from '@utils/calc-percent-questions';
import { Container } from '@mui/system';

// ----------------------------------------------------------------------

export default function HeaderSimple() {
  const { job } = useSelector(state => state.fastInterview);

  return (
    <>
      <AppBar sx={{ boxShadow: '0px 0px 20px 5px rgb(0 0 0 / 6%)', backgroundColor: 'white' }}>
        <Container component='main'>
          <Toolbar
            sx={{
              p: 0,
              justifyContent: 'space-between',
              height: {
                xs: HEADER.H_MOBILE,
                md: HEADER.H_DESKTOP,
              },
            }}
          >
            <Logo disabledLink />

            <Stack direction='row' alignItems='center' spacing={0}>
              <SettingsButton />

              <AccountPopover />
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>

      {job?.currentQuestion && job?.totalQuestion && (
        <LinearProgress
          variant='determinate'
          value={calcPercentQuestions(job?.currentQuestion - 1, job?.totalQuestion)}
          sx={{
            top: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_DESKTOP,
            },
            zIndex: 999,
            backgroundColor: '#E0E0E0',
            position: 'absolute',
            left: 0,
            width: '100%',
          }}
        />
      )}
    </>
  );
}
