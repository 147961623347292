export const paths = {
  root: '/',
  page404: '/404',

  startInterview: {
    root: (jobId, companyId) => `${getFastInterviewRoot(jobId, companyId)}/start-interview`,
    privacyPolicies: '/privacy-policies',
    auth: {
      login: (jobId, companyId, integration) => `${getFastInterviewRoot(jobId, companyId)}/login${integration ? `?&integracao=${integration}` : ''}`,
      register: (jobId, companyId) => `${getFastInterviewRoot(jobId, companyId)}/register`,
    },
  },

  questions: {
    root: (jobId, companyId) => getQuestionRoot(jobId, companyId),
    testAudio: (jobId, companyId) => `${getQuestionRoot(jobId, companyId)}/test-audio`,
    testVideo: (jobId, companyId) => `${getQuestionRoot(jobId, companyId)}/test-video`,
    stoppedPage: (jobId, companyId) => `${getQuestionRoot(jobId, companyId)}/finished-page`,
    feedback: (jobId, companyId) => `${getQuestionRoot(jobId, companyId)}/feedback`,
    orientations: (jobId, companyId) => `${getQuestionRoot(jobId, companyId)}/orientation`,
  },
};

const getFastInterviewRoot = (jobId, companyId) => {
  return `/job/${jobId}/company/${companyId}`;
};

const getQuestionRoot = (jobId, companyId) => {
  return `/questions${getFastInterviewRoot(jobId, companyId)}`;
};
